@import './../../../styles/common/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.dhsv_geoip-detect {
  z-index: 99;
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  // #774f4b
  background: #09332c;
  background-size: 400% 400%;
  animation: animation 20s ease infinite;
  //box-shadow: 0 0 10px 5px rgba($secondary, 0.75);
  //border: 1px solid white;
  color: white;

  @keyframes animation {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  &_wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.25rem 6rem;
    //padding-right: $space-sm;

    @include media-breakpoint-down(md) {
      padding: 0.25rem 2rem;
    }

    p {
      margin: 0;
      font-size: 1rem;

      a {
        margin: 0;
        text-decoration: underline;

        &:hover {
          opacity: 0.85;
        }
      }
    }

    a {
      margin: 0 $space-sm;
    }

    > span {
      cursor: pointer;
      padding: 5px;
      font-size: 1.5rem;

      &:hover {
        color: $secondary;
      }
    }

    .btn {
      background-color: white;
      color: #33607c;
      text-transform: uppercase;
      padding: 0.185rem 1.5rem;
      font-size: 0.775rem;

      &:hover {
        opacity: 0.85;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    top: 70px;
  }

  @include media-breakpoint-down(md) {
    top: 50px;
  }

  @include media-breakpoint-down(sm) {
    &_wrapper {
      text-align: center;

      p {
        flex: 100%;
        margin-bottom: $space-xs;
      }

      a {
        margin-left: 0;
      }
    }
  }
}
