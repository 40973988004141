.social-icons {
  justify-content: space-around;
}

.social-icons a {
  margin: 0 5px 10px;
}

@media (min-width: 1600px) {
  html {
    font-size: 1rem;
  }
}

.dhsv_geoip-detect {
  z-index: 99;
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  background: #09332c;
  background-size: 400% 400%;
  animation: animation 20s ease infinite;
  color: #fff;
}

@keyframes animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.dhsv_geoip-detect_wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: .25rem 6rem;
}

@media (max-width: 991.98px) {
  .dhsv_geoip-detect_wrapper {
    padding: .25rem 2rem;
  }
}

.dhsv_geoip-detect_wrapper p {
  margin: 0;
  font-size: 1rem;
}

.dhsv_geoip-detect_wrapper p a {
  margin: 0;
  text-decoration: underline;
}

.dhsv_geoip-detect_wrapper p a:hover {
  opacity: .85;
}

.dhsv_geoip-detect_wrapper a {
  margin: 0 2rem;
}

.dhsv_geoip-detect_wrapper>span {
  cursor: pointer;
  padding: 5px;
  font-size: 1.5rem;
}

.dhsv_geoip-detect_wrapper>span:hover {
  color: #e9511d;
}

.dhsv_geoip-detect_wrapper .btn {
  background-color: #fff;
  color: #33607c;
  text-transform: uppercase;
  padding: .185rem 1.5rem;
  font-size: .775rem;
}

.dhsv_geoip-detect_wrapper .btn:hover {
  opacity: .85;
}

@media (max-width: 1199.98px) {
  .dhsv_geoip-detect {
    top: 70px;
  }
}

@media (max-width: 991.98px) {
  .dhsv_geoip-detect {
    top: 50px;
  }
}

@media (max-width: 767.98px) {
  .dhsv_geoip-detect_wrapper {
    text-align: center;
  }

  .dhsv_geoip-detect_wrapper p {
    flex: 100%;
    margin-bottom: 1rem;
  }

  .dhsv_geoip-detect_wrapper a {
    margin-left: 0;
  }
}

